import React from 'react'
import { Helmet } from "react-helmet"
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import '../../styles/altonabaptist.scss';
import background from '../../images/altonabaptist/lopez-robin-apax4M-4kFI-unsplash.jpg'

const AltonaBaptist = () => {
  return (
    <>
      <Helmet
        htmlAttributes={{
            class: 'bd-altona-baptist-sample'
        }}>
          <title>Brolga Digital || Altona Baptist Church</title>
          <link rel="stylesheet" href="https://use.typekit.net/xnh3scp.css"></link>
      </Helmet>

      {/* NAVIGATION */}
      <div uk-navbar='' className='uk-navbar-container uk-navbar-transparent'>
            <div className='uk-navbar-left'>
                <StaticImage src='../../images/altonabaptist/logo-sample-01.png' alt='' height={100} style={{position: 'absolute', top: '5px', left: '5px' }}/>
                <Link className="uk-navbar-item uk-logo" to="#" style={{marginLeft: '75px'}}>Altona Baptist Church</Link>
            </div>
            <div className='uk-navbar-right'>
                <ul className='uk-navbar-nav'>
                    <li><Link to='#'>Home</Link></li>
                    <li><Link to='#'>About</Link></li>
                    <li><Link to='#'>Ministries</Link></li>
                    <li><Link to='#'>Sermons</Link></li>
                    <li><Link to='#'>Contact</Link></li>
                </ul>
            </div>
        </div>

    

      {/* HERO SECTION */}
        <div class="uk-section-default uk-margin-xlarge-bottom">
            <div className='uk-section uk-position-relative'>
                <div class="uk-container">
                    <div className='uk-width-1-2@s'>
                        <h1>Altona Baptist Church</h1>
                        <p>Spreading the good news of Jesus Christ to the city of Altona and surrounding areas</p>
                        <Link className='uk-button uk-button-primary uk-margin-right' to='#'>Join Us!</Link>
                        <Link className='uk-button uk-button-default' to='#'>Download a Sermon</Link>
                    </div>
                </div>
                <div class="bd-hero uk-background-cover" style={{backgroundImage: `url(${background})`, backgroundPosition: '100% 90%'}}></div>
            </div>
        </div>

        <div className='uk-container uk-container-medium'>
            <p style={{margin: '0 0 -45px 0'}}>Welcome To</p>
            <h1>Altona Baptist Church</h1>

            <div className='uk-flex-between' uk-grid=''>
                <div className='uk-width-2-3@s'>
                    <p>​As we pass through these doors, may our hearts, our eyes and our ears be open to God's mighty presence.</p>
                    <p>
                    The Sunday morning service provides us with an opportunity to reach out as one to our Heavenly Father. This service is contemporary and has energy, Bible readings, passion, music and fun. </p>
                    <StaticImage src='../../images/altonabaptist/pexels-jonathan-meyer-612997.jpg' alt=''/>
                    <p>As members of the Christian faith, following the traditions of the Baptist Church, we affirm and assert the importance of sharing our faith in Christ, recognising his supreme sacrifice and resurrection that leads to repentance and salvation.</p>

                    <h2>More than Sunday</h2>
                    <p>In addition to our Sunday service, we also run a variety of meetings during the week. </p>
                    
                    <ul class='uk-list uk-flex uk-flex-row uk-flex-between uk-width-1-1'>
                        <li><Link to='#'>Men's Study</Link></li>
                        <li><Link to='#'>Ladies Study</Link></li>
                        <li><Link to='#'>Prayer Nights</Link></li>
                        <li><Link to='#'>Home Groups</Link></li>
                        <li><Link to='#'>Kids Club</Link></li>
                    </ul>
                

                    <h2>Meet Our Staff</h2>
                    <div className='uk-child-width-1-2@s uk-grid-large' uk-grid=''>
                        <div><div className='uk-card'>
                            <div className='uk-grid-small' uk-grid=''>
                                <div class='uk-width-1-3'>
                                    <StaticImage src='../../images/altonabaptist/screenshot-268_1.jpg'/>
                                </div>
                                <div className='uk-width-2-3'>
                                    <h4>Rev Andrew Arthurs</h4>
                                    <hr />
                                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et.</p>
                                </div>
                            </div>
                        </div></div>
                        <div className='uk-card'>
                            <div className='uk-grid-small' uk-grid=''>
                                <div class='uk-width-1-3'>
                                    <StaticImage src='../../images/altonabaptist/geoff.jpg'/>
                                </div>
                                <div className='uk-width-2-3'>
                                    <h4>Geoff Drayton</h4>
                                    <hr />
                                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et.</p>
                                </div>
                            </div>
                        </div>
                        <div className='uk-card'>
                            <div className='uk-grid-small' uk-grid=''>
                                <div class='uk-width-1-3'>
                                    <StaticImage src='../../images/altonabaptist/screenshot-2560_1.png'/>
                                </div>
                                <div className='uk-width-2-3'>
                                    <h4>Kathleen Vass</h4>
                                    <hr />
                                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et.</p>
                                </div>
                            </div>
                        </div>
                        <div className='uk-card'>
                            <div className='uk-grid-small' uk-grid=''>
                                <div class='uk-width-1-3'>
                                    <StaticImage src='../../images/altonabaptist/screenshot-792-1_1.jpg' />
                                </div>
                                <div className='uk-width-2-3'>
                                    <h4>Scott Vost</h4>
                                    <hr />
                                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et.</p>
                                </div>
                            </div>
                        </div>
                        <div className='uk-card'>
                            <div className='uk-grid-small' uk-grid=''>
                                <div class='uk-width-1-3'>
                                    <StaticImage src='../../images/altonabaptist/screenshot-2404-1.png' />
                                </div>
                                <div className='uk-width-2-3'>
                                    <h4>Luis Jovel</h4>
                                    <hr />
                                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                
                </div>
                <div className='uk-width-1-4@s'>
                    <h3>Regular Services</h3>
                    <p>
                        Sunday 10am<br/>
                        Sunday School 10am
                    </p>
                    <h3>Church News</h3>
                    <p>
                        We are back! From Sunday, January 30 we can meet in the church building. Make sure you bring a mask and practice social distancing
                    </p>
                    <h3>Latest Sermon</h3>
                    <p>1 Peter 4 - Andrew Arthurs</p>
                    <Link to='#' className='uk-button uk-button-default'>Listen Now</Link>

                </div>
            </div>
        </div>
        <footer className='uk-section uk-margin-xlarge-top'>
            <div className='uk-container uk-container-medium uk-text-right uk-float-right' uk-grid=''>
                <div>
                    <h3>Altona Baptist Church</h3>
                    <p>
                        14 Sargood St, Altona, 3018 <br />
                        (03) 9398 2829 <br />
                        altonabaptist@gmail.com
                    </p>
                    <ul className='uk-list uk-flex uk-flex-row  uk-flex-right'>
                        <li><Link to='#' uk-icon="icon: facebook; ratio: 2"></Link></li>
                        <li><Link to='#' uk-icon="icon: location; ratio: 2"></Link></li>
                    </ul>
                </div>
                <StaticImage src='../../images/altonabaptist/logo-sample-01.png' height={150}/>

            </div>
        </footer>

        {/* COPYRIGHT */}
        <div className='bd-copyright uk-text-center uk-padding-small'>
            <p className='uk-margin-remove-bottom'>&copy; <Link to='https://brolgadigital.com.au'>Brolga Digital</Link> {new Date().getFullYear()}</p>
        </div>
    </>
  )
}

export default AltonaBaptist